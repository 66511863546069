@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat.ttf") format('truetype');
}

.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .projects .projectsTitle {
    font-family: "Montserrat", 'Gill Sans', 'Gill Sans MT', 'Trebuchet MS', sans-serif;
    font-size: 3.25em;
    font-weight: 550;
    color: white;
    padding: 2rem;
  }
  
  .projectsList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    color: white;
    padding-top: 1.5rem;
  }

  .projectsList a{
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  
  /* PROJECT ITEM STYLING */
  
  .projectItem {
    border-radius: 15px;
    width: 300px;
    height: 350px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 15px rgba(177, 162, 162, 0.2);
    background-color: rgba(255, 255, 255, 0.35);
  }
  .projectItem:hover {
    box-shadow: 0px 3px 15px rgba(208, 196, 196, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
  }
  
  .projectItem div {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 170px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .projectItem h1,
  .projectItem .skill {
    margin-left: 20px;
  }

  .projectItem h1{
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1.2em;
  }

  .projectItem .skill{
    list-style-type: none;
  }

  .projectItem .tag .skill{
    padding-right: 0.90rem;
    line-height: 1.75rem;
    border-radius: 50%;
  }
  
  @media only screen and (max-width: 1300px) {
    .projectsList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectsList {
      grid-template-columns: 1fr;
    }
  }