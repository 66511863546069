*{
  /*DO NOT TOUCH*/
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat.ttf") format('truetype');
}



.home {
    width: 100%;
    /* height: 90vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Montserrat", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
  }
  
  .headerContainer {
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    /*margin-left: 50px;*/
  }

  .nameTitle{
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  
  .headerContainer .nameTitle h1 {
    padding-bottom: 1rem;
    text-align: center;
    font-size: 4.17em;
    font-weight: 600;
    color: rgb(255, 255, 255);
  }
  
  .headerContainer .nameTitle h3 {
    padding: 0;
    text-align: center;
    font-size: 1.78em;
    letter-spacing: 0.13em;
    font-family: 'Gill Sans MT', 'Gill Sans', 'Trebuchet MS', sans-serif;
    font-weight: lighter;
    color: rgb(255, 255, 255);
  }

  .headerContainer img {
    height: auto;
    width: 43.68%;
  }
  
  .headerContainer button {
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    background-color: #121619;
    height: 50px;
    width: 180px;
    color: white;
    text-decoration: none;
    font-size: 20px;
  }
  
  .headerContainer button:hover {
    background-color: #07090a;
    cursor: pointer;
    transition: 0.3s ease-in;
  }
  
  @media only screen and (max-width: 650px) {
    .home {
      justify-content: center;
      align-items: center;
    }
   
    .headerContainer {
      margin-left: 0px;
      border-radius: 10px;
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      /* background-color: #121619; */
      color: white;
    }
  
    .headerContainer .nameTitle h1 {
      font-size: 2.08em;
      padding-bottom: 3rem;
      height: 30px;
      color: rgb(255, 255, 255);
    }
  
    .headerContainer .nameTitle h3 {
      font-size: .90em;
      color: white;
    }

    .headerContainer img {
      height: auto;
      width: 100.40%;
    }
  
    .headerContainer button {
      background-color: white;
      color: #121619;
      margin-bottom: 30px;
    }
  
    .headerContainer button:hover {
      background-color: rgb(225, 225, 225);
      color: #121619;
    }
  }