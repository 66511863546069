@font-face {
  font-family: "RobotoMono";
  src: url("../assets/fonts/RobotoMono.ttf") format('truetype');
}

.close {
    width: 100%;
    height: 15vh;
    /* background-color: #121619; */
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .close .leftSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-left: 150px;
  }
  
  .close .leftSide img {
    width: auto;
    height: 40%;
    margin-top: 2rem;

  }
  
  .close .rightSide {
    flex: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .close .rightSide a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-family: "RobotoMono", 'Gill Sans MT', 'Gill Sans';
    font-size: 1.115rem;
  }
  
  .close button {
    display: none;
  }
  
  

  .rightSide .resume-button{
    background-color: transparent;
    border: 1px solid white;
    padding: 0.75rem 1rem;
    border-radius: .25rem;
    color: white;
    cursor: pointer;
  }
  
  
  @media(max-width: 768px){
    .close{
      position: relative;
    }
  
    .open{
      height: 100vh;
    }
  
    .open .rightSide{
      position: absolute;
      z-index: 9;
      /* top: 1rem;
      top: 120px; */
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }
  
    .open .rightSide a{
      margin: 15px 0;
      font-size: 24px;
      color: white;
      text-decoration: none;
    }

    .open button{
      display: none;
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      position: absolute;
      top: 28px;
      right: 20px;
    }

    .open .closeButton button{
      display: block;
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      position: absolute;
      top: 28px;
      right: 20px;
    }

    .open .leftSide{
      display: flex;
      justify-content: center;
      align-items: center;

    }
  
    .close .leftSide img{
      position: absolute;
      margin: 2rem;
      /* top: 23px; */
      left: .5rem;
    }

    .close .closeButton button{
      display: none;
    }
  
    .close button{
      display: block;
      background-color: transparent;
      border: none;
      color: white;
      cursor: pointer;
      position: absolute;
      top: 28px;
      right: 20px;
    }

    .close svg {
      font-size: 40px;
    }
  
    .close .rightSide{
      position: absolute;
      top: -300px;
      left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }
  
    .close .rightSide a{
      margin: 15px 0;
      font-size: 24px;
    }
  }