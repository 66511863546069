@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat.ttf") format('truetype');
  }

@font-face {
font-family: "Blinker";
src: url("../assets/fonts/Blinker.ttf") format('truetype');
}

.page{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
}

/* Top Section Styling */

.top-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: .75fr;
    grid-template-areas: 
        'overview pic';
    padding-bottom: 3.5rem;
}

.top-section .overview{
    grid-area: overview;
    margin-right: 1.5em;
}

.top-section h1{
    font-size: 2.75rem;
    font-weight: 600;
    font-family: "Montserrat", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
    
}

.top-section p{
    font-size: 1.5rem;
    font-family: "Blinker", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
    padding-top: 1.2em;
    align-self: stretch;
    margin-right: 1em;
}

.top-section img{
    grid-area: pic;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    width: 85%;
}

.middle-section{
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    font-family: "Blinker", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;

}

.middle-section .middle-container{
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.middle-section .middle-container .middle-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        'Goals Role'
        'Goals Tools';
    
    /* place-items: center; */

    padding-top: 3.5em;
    padding-bottom: 3.5em;
    
}

.middle-grid h2{
    font-size: 1.5rem;
    margin-right: 1em;
    padding-bottom: .5em;
    text-decoration: underline;
}

.middle-grid p{
    font-size: 1.2rem;
    margin-right: 1.5em;
    padding-bottom: 1.75em;
}


.middle-container .goal{
    grid-area: Goals;
    font-family: "Blinker", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
    border-right: 3px solid white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    

.middle-container .role h2,
.middle-container .tools h2,
.middle-container .role p,
.middle-container .tools p
{
    margin-left: 50px;
}



.middle-grid .role{
    grid-area: Role;
    border-bottom: 3px solid white;
    
}

.middle-grid .tools{
    grid-area: Tools;
}

.middle-grid .tools h2{
    grid-area: Tools;
    margin-top: 1rem;
}

.process-section {
    /* margin-top: .1rem; */
    display: flex;
    align-items: center;
    flex-direction: column;
}
  
.process-section h3 {
    font-size: 2rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
    padding: 3rem;
}

.process-section p {
    font-size: 1.2rem;
    padding-top: 1.5rem;
    padding-bottom: 2.3rem;
    margin-left: 20%;
    margin-right: 20%;
    font-family: "Blinker", "Gill Sans", "Gill Sans MT", "Trebuchet MS", sans-serif;
}

.process-section .indv-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* height: 70%; */
    padding: 1em;
}

.process-section .indv-item img {
    max-width: 100%;
    /* Set a maximum height for the image (e.g., 200 pixels) */
    max-height: 300px;
}

@media only screen and (max-width: 1300px) {
    .top-section{
        display: flex;
        flex-direction: column;
    }

    .top-section .overview{
        margin-right: 0px;
    }

    .top-section h1{
        font-size: 2.2rem;
        font-weight: 550;
    }

    .top-section p{
        font-size: 1.35rem;
        padding-top: 1em;
        padding-bottom: 1.75em;
        align-self: stretch;
        margin-right: 0;
    }

    .top-section img{
        grid-area: pic;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        width: 95%;
        padding-bottom: 3rem;
    }
}


@media only screen and (max-width: 850px) {
    .middle-section .middle-container .middle-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
            'Goals Goals'
            'Role Tools';
        
        /* place-items: center; */
    
        padding-top: 1em;
        padding-bottom: 3.5em;
        
    }

    .middle-container .goal{
        grid-area: Goals;
        border-bottom: 3px solid white;
        border-right: 0px;
    }

    .middle-container .role h2,
    .middle-container .tools h2,
    .middle-container .role p,
    .middle-container .tools p
    {
        margin-left: 0px;
    }

    .middle-container .role h2,
    .middle-container .tools h2{
        margin-top: 1rem;
    }

    .middle-grid .role{
        grid-area: Role;
        border-bottom: 0;
        border-right: 3px solid white;
        
    }

    .middle-grid .tools{
        grid-area: Tools;
        margin-left: 1rem;
    }

    .process-section p {
        font-size: 1.125rem;
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        margin-left: 10%;
        margin-right: 10%;
    }
}
